<template>
  <div v-if="!isProduct && page.author" class="author-block">
    <span class="image">
      <img :src="page.author.avatar.image_object" />
    </span>
    <span class="text">
      {{ locale.TEXTS.authorText }}:
      <b>{{ page.author.name }}</b>
      <span>·</span>{{ dateFormat(page.created_at) }}
    </span>
  </div>
  <div class="blocks">
    <div
      v-for="item in blocks"
      :key="item"
      :class="{
        'alignment-1': item.alignment === 1,
        'alignment-2': item.alignment === 2,
        'alignment-3': item.alignment === 3,
        'is-desktop': item.is_display_desktop,
        'is-tablet': item.is_display_tablet,
        'is-mobile': item.is_display_mobile,
      }"
      class="block-item"
    >
      <div
        v-if="item.type === 16"
        class="columns"
        :class="'count-' + Number(item.content)"
      >
        <div
          class="column-item"
          v-for="column in Number(item.content)"
          :key="column"
          :class="{
            'desktop-justification-1':
              columns[item.id][column][0].desktop_justification === 1,
            'desktop-justification-2':
              columns[item.id][column][0].desktop_justification === 2,
            'desktop-justification-3':
              columns[item.id][column][0].desktop_justification === 3,
            'tablet-justification-1':
              columns[item.id][column][0].tablet_justification === 1,
            'tablet-justification-2':
              columns[item.id][column][0].tablet_justification === 2,
            'tablet-justification-3':
              columns[item.id][column][0].tablet_justification === 3,
            'mobile-justification-1':
              columns[item.id][column][0].mobile_justification === 1,
            'mobile-justification-2':
              columns[item.id][column][0].mobile_justification === 2,
            'mobile-justification-3':
              columns[item.id][column][0].mobile_justification === 3,
            'tablet-order-index-1':
              columns[item.id][column][0].tablet_order_index === 1,
            'tablet-order-index-2':
              columns[item.id][column][0].tablet_order_index === 2,
            'tablet-order-index-3':
              columns[item.id][column][0].tablet_order_index === 3,
            'tablet-order-index-4':
              columns[item.id][column][0].tablet_order_index === 4,
            'tablet-order-index-5':
              columns[item.id][column][0].tablet_order_index === 5,
            'tablet-order-index-6':
              columns[item.id][column][0].tablet_order_index === 6,
            'mobile-order-index-1':
              columns[item.id][column][0].mobile_order_index === 1,
            'mobile-order-index-2':
              columns[item.id][column][0].mobile_order_index === 2,
            'mobile-order-index-3':
              columns[item.id][column][0].mobile_order_index === 3,
            'mobile-order-index-4':
              columns[item.id][column][0].mobile_order_index === 4,
            'mobile-order-index-5':
              columns[item.id][column][0].mobile_order_index === 5,
            'mobile-order-index-6':
              columns[item.id][column][0].mobile_order_index === 6,
          }"
        >
          <div
            v-for="block in columns[item.id][column]"
            :key="block"
            class="column-block"
            :class="{
              'alignment-1': block.alignment === 1,
              'alignment-2': block.alignment === 2,
              'alignment-3': block.alignment === 3,
              'is-desktop': block.is_display_desktop,
              'is-tablet': block.is_display_tablet,
              'is-mobile': block.is_display_mobile,
            }"
          >
            <EditorElementBlock
              :block="block"
              :sourcePage="sourcePage"
              :articleId="articleId"
            />
          </div>
        </div>
      </div>
      <EditorElementBlock
        v-else
        :block="item"
        :sourcePage="sourcePage"
        :articleId="articleId"
      />
    </div>
    <div v-if="page.id === 23" class="alignment-1 is-desktop block-item">
      <div>
        <p>Челлендж начнётся</p>
        <p>через <span id="t"></span></p>
        <p>Пройдет ли Виктор дистанцию?</p>
        <p>Голосуй и узнай, что думают другие</p>
      </div>
    </div>
  </div>
</template>

<script>
import pageMixin from "@/mixins/pageMixin";
import productMixin from "@/mixins/productMixin";
import EditorElementBlock from "@/components/EditorElementBlock.vue";
import Locale from "@/api/locale";
import "@/assets/css/blog.css";

export default {
  mixins: [pageMixin, productMixin],
  components: {
    EditorElementBlock,
  },
  props: {
    page: Object,
    isProduct: Boolean,
    sourcePage: Number,
  },
  data() {
    return {
      blocks: [],
      columns: {},
      locale: Locale,
      articleId: null,
    };
  },
  created() {
    if (this.sourcePage === 5) this.articleId = this.page.id;
    for (let item in this.page.blocks) {
      item = this.page.blocks[item];
      if (item.type === 10) this.getBasketProduct(item.product);
      if (item.type === 16) {
        this.columns[item.id] = {};
        for (let column = 1; column <= Number(item.content); column += 1) {
          this.columns[item.id][column] = [];
        }
      }
      if (item.block_id) {
        this.columns[item.block_id][item.number_column].push(item);
      } else this.blocks.push(item);
    }
    // console.log(this.columns);
    // let timeend = new Date();
    // timeend = new Date(2024, 11, 24, 0, 0);
    // function time() {
    //     let today = new Date();
    //     today = Math.floor((timeend - today) / 1000);
    //     let  tsec = today%60;
    //     today = Math.floor(today / 60);
    //     if(tsec < 10) tsec = '0' + tsec;
    //     let tmin = today % 60;
    //     today = Math.floor(today / 60);
    //     if(tmin < 10) tmin= '0' + tmin;
    //     let thour = today % 24;
    //     today = Math.floor(today / 24);
    //     let timestr = today +" дней "+ thour+" часов "+tmin+" минут "+tsec+" секунд";
    // document.getElementById("t").innerHTML = timestr;
    // window.setTimeout("time()", 1000);
    // }
    // window.setTimeout("time()", 1000);
  },
  mounted() {
    let that = this;
    document.addEventListener("keydown", function (evt) {
      if (evt.which === 27) {
        for (let item in that.blocks) {
          that.blocks[item].isOpen = false;
        }
        that.isOpenPopup(false);
      }
    });
  },
};
</script>
