<template>
  <h1 v-if="item.type === 1" v-html="item.content"></h1>
  <h2 v-else-if="item.type === 2" v-html="item.content"></h2>
  <h3 v-else-if="item.type === 3" v-html="item.content"></h3>
  <h4 v-else-if="item.type === 4" v-html="item.content"></h4>
  <p v-else-if="item.type === 5" v-html="item.content"></p>
  <div v-else-if="item.type === 6" v-html="item.content" class="quote"></div>
  <div v-else-if="item.type === 7" v-html="item.content" class="list"></div>
  <div v-else-if="item.type === 8" v-html="item.content" class="number"></div>
  <div v-else-if="item.type === 9" class="image">
    <img @click="openImageGallery(item, true)" :src="item.image.image_object" />
    <div v-if="item.isOpen" class="gallery-popup shop-popup unselectable">
      <div class="content">
        <span class="close" @click="openImageGallery(item, false)">
          <img src="@/assets/images/shop-close.svg" />
        </span>
        <swiper
          class="swiper"
          :modules="modules"
          :slides-per-view="1"
          :style="{
            '--swiper-navigation-color': '#000',
            '--swiper-navigation-size': '32px',
          }"
        >
          <swiper-slide v-if="checkZoom(item)" :class="{ zoom: item.isZoom }">
            <span class="image-item">
              <img
                :src="item.image.image_object"
                @click.stop="
                  item.isZoom ? (item.isZoom = false) : (item.isZoom = true)
                "
              />
            </span>
          </swiper-slide>
          <swiper-slide v-else class="not-zoom">
            <span class="image-item">
              <img :src="item.image.image_object" />
            </span>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
  <div
    v-else-if="item.type === 10"
    class="video"
    v-html="displayVideo(item.content)"
  ></div>
  <div
    v-else-if="
      item.type === 11 && !item.product.is_deleted && item.product.type === 3
    "
    class="products-block"
  >
    <div
      class="editor-product-images"
      :class="'count-' + item.product.products.length"
    >
      <div
        class="editor-product-image"
        v-for="product in item.product.products"
        :key="product"
      >
        <a
          :style="{
            background: product.product.images[0].image.background_color,
          }"
        >
          <img :src="product.product.images[0].image.image_object_middle" />
        </a>
        <span></span>
      </div>
    </div>
    <span class="name">{{ item.product.headline_preview }}</span>
    <div
      class="editor-product-name"
      v-for="product in item.product.products"
      :key="product"
    >
      <span>{{ product.product.headline_preview }}</span>
      <span>x{{ product.quantity }} шт.</span>
    </div>
    <div class="info">
      <div>
        <span class="price" v-if="item.product.type === 4">
          <span class="new">Цена по номиналу</span>
        </span>
        <span class="price" v-else>
          <span class="new">
            {{ productPriceNew(item.product) }}
            {{ locale.TEXTS.currencyText }}
          </span>
          <span v-if="item.product.discount_price" class="old">
            {{ formatPrice(item.product.website_price) }}
            {{ locale.TEXTS.currencyText }}
          </span>
        </span>
        <span v-if="item.product.discount_price" class="benefit">
          {{ productBenefit(item.product) }}
        </span>
      </div>
      <button
        v-if="item.product.productBasket > 0"
        @click="nextOnCart"
        class="product-basket-active"
      >
        <span>В корзине {{ item.product.productBasket }} шт.</span>
        <span>Перейти</span>
      </button>
      <button
        v-else-if="item.product.type !== 4"
        @click.stop="
          clickBasketProduct(item.product, 1, true, sourcePage, articleId)
        "
        :class="
          'basket-simple-' + item.product.id + ' basket-' + item.product.id
        "
      >
        {{ locale.TEXTS.toBasketText }}
      </button>
    </div>
  </div>
  <div
    v-else-if="item.type === 11 && !item.product.is_deleted"
    class="product-block"
  >
    <a
      class="image"
      :style="{
        background: item.product.images[0].image.background_color,
      }"
      :href="'/catalog/' + item.product.shop_page[0].url"
      target="_blank"
    >
      <img :src="item.product.images[0].image.image_object_middle" />
    </a>
    <div class="info">
      <span class="name">{{ item.product.headline_preview }}</span>
      <span class="quantity" v-if="item.product.type !== 4">
        {{ item.product.feature_product_quantity }}
        <span v-if="item.product.release_form">
          {{
            getDeclination(
              item.product.feature_product_quantity,
              item.product.release_form
            )
          }}
        </span>
      </span>
      <span class="price" v-if="item.product.type === 4">
        <span class="new">Цена по номиналу</span>
      </span>
      <span class="price" v-else>
        <span class="new">
          {{ productPriceNew(item.product) }}
          {{ locale.TEXTS.currencyText }}
        </span>
        <span v-if="item.product.discount_price" class="old">
          {{ formatPrice(item.product.website_price) }}
          {{ locale.TEXTS.currencyText }}
        </span>
      </span>
      <span v-if="item.product.discount_price" class="benefit">
        {{ productBenefit(item.product) }}
      </span>
      <button
        v-if="item.product.productBasket > 0"
        @click="nextOnCart"
        class="product-basket-active"
      >
        <span>В корзине {{ item.product.productBasket }} шт.</span>
        <span>Перейти</span>
      </button>
      <button
        v-else-if="item.product.type !== 4"
        @click.stop="
          clickBasketProduct(item.product, 1, true, sourcePage, articleId)
        "
        :class="
          'basket-simple-' + item.product.id + ' basket-' + item.product.id
        "
      >
        {{ locale.TEXTS.toBasketText }}
      </button>
    </div>
  </div>
  <div v-else-if="item.type === 12" class="article">
    <a
      :href="'/blog/' + item.articles[0].shop_page[0].url"
      class="item"
      target="_blank"
    >
      <span
        v-if="item.banner"
        class="image"
        :style="{
          background: 'url(' + item.articles[0].banner.image_object + ')',
        }"
      ></span>
      <span class="text">
        <span v-if="item.articles[0].categories.length > 0" class="category">
          {{ textCategories(item.articles[0].categories) }}
        </span>
        <span class="name">{{ item.articles[0].shop_page[0].title }}</span>
      </span>
    </a>
  </div>
  <div v-else-if="item.type === 13" class="articles-items">
    <div class="article-item" v-for="article in item.articles" :key="article">
      <ArticleItemBlock :item="article" />
    </div>
  </div>
  <div v-else-if="item.type === 14" class="button">
    <button @click.stop="clickButton(item)">{{ item.content }}</button>
  </div>
  <div v-else-if="item.type === 15" class="break"></div>
  <div v-else-if="item.type === 17" class="table">
    <table v-html="item.content"></table>
  </div>
  <div v-else-if="item.type === 18" class="html-code">
    <div v-html="getHTMLBlock(item.content)"></div>
  </div>
</template>

<script>
import mainMixin from "@/mixins/mainMixin";
import pageMixin from "@/mixins/pageMixin";
import productMixin from "@/mixins/productMixin";
import ArticleItemBlock from "@/components/ArticleItemBlock.vue";
import { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import Locale from "@/api/locale";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

export default {
  mixins: [mainMixin, pageMixin, productMixin],
  components: {
    ArticleItemBlock,
    Swiper,
    SwiperSlide,
  },
  props: {
    block: Object,
    sourcePage: Number,
    articleId: Number,
  },
  setup() {
    return {
      modules: [Pagination, Navigation],
    };
  },
  data() {
    return {
      item: {},
      blocks: [],
      locale: Locale,
    };
  },
  created() {
    this.item = this.block;
  },
  mounted() {
    let that = this;
    document.addEventListener("keydown", function (evt) {
      if (evt.which === 27) {
        that.item.isOpen = false;
        that.openImageGallery(that.item, false);
      }
    });
  },
  methods: {
    getHTMLBlock(value) {
      let html = value.split("<htmlcode>");
      if (html.length < 2) return "";

      let htmlnumber = value.split("<htmlnumber>");
      if (htmlnumber.length < 2) return "";

      htmlnumber = htmlnumber[1].split("</htmlnumber>");
      htmlnumber = htmlnumber[htmlnumber.length - 2];
      let blockId = "html_block_" + htmlnumber;
      html = html[1].split("</htmlcode>");
      html = html[html.length - 2];
      let div = document.createElement("div");
      div.id = blockId;
      div.innerHTML = html;
      let jscode = value.split("<jscode>");
      if (jscode.length > 1) {
        jscode = jscode[1].split("</jscode>");
        jscode = jscode[jscode.length - 2];
        setTimeout(jscode, 100);

        // window.addEventListener("scroll", function () {
        //   let element = document.getElementById("html_block_4");
        //   let rect = element.getBoundingClientRect();
        //   let y = window.pageYOffset - rect.top;
        //   let image1 = element.getElementsByTagName("img")[0];
        //   let image1_value = y - 50;
        //   if (image1_value < 0) image1_value = 0;
        //   if (image1_value > 30) image1_value = 30;
        //   image1.style.top = image1_value + "px";
        //   let image2 = element.getElementsByTagName("img")[1];
        //   let image2_value = y - 150;
        //   if (image2_value < 0) image2_value = 0;
        //   if (image2_value > 50) image2_value = 50;
        //   if (image2_value > 0) image2_value *= -1;
        //   image2.style.top = image2_value + "px";
        //   let div1 = element.getElementsByTagName("div")[1];
        //   let div2 = element.getElementsByTagName("div")[2];
        //   let div_value = y - 430;
        //   if (div_value < 0) div_value = 0;
        //   if (div_value > 30) div_value = 30;
        //   div1.style.top = div_value + "px";
        //   div2.style.top = div_value + "px";
        // });
        // document.getElementById("html_block_4")
        // window.addEventListener("scroll", function () {
        //   let element = document.getElementById("html_block_4");
        //   let rect = element.getBoundingClientRect();
        //   let y = rect.top;
        //   console.log(y);
        //   if (y > 0) return;
        //   let image1 = element.getElementsByTagName("img")[0];
        //   let image1_value = y + 50;
        //   if (image1_value < -10) image1_value = -10;
        //   if (image1_value > 30) image1_value = 30;
        //   image1.style.top = image1_value + "px";
        // });
      }
      return div.outerHTML;
    },
    productPriceNew(product) {
      if (product.discount_price) {
        return this.formatPrice(product.discount_price);
      }
      return this.formatPrice(product.website_price);
    },
    productBenefit(product) {
      let productAmount = product.website_price - product.discount_price;
      productAmount = this.formatPrice(productAmount);
      let productPercent = product.discount_price * 100;
      productPercent = productPercent / product.website_price;
      productPercent = Math.round(100 - productPercent);
      return (
        Locale.TEXTS.yourBenefit +
        " " +
        productPercent +
        "% (-" +
        productAmount +
        " " +
        Locale.TEXTS.currencyText +
        ".)"
      );
    },
    checkZoom(item) {
      if (item.image.width > window.innerWidth) return true;
      if (item.image.height > window.innerHeight) return true;
      return false;
    },
    openImageGallery(item, isOpen) {
      item.isOpen = isOpen;
      if (isOpen) document.body.style.overflow = "hidden";
      else document.body.style.overflow = "auto";
    },
    displayVideo(value) {
      let video = "";
      if (value.indexOf("outube.com/watch?v=") !== -1) {
        video = value.split("outube.com/watch?v=")[1].slice(0, 11);
        video =
          "<iframe src='https://www.youtube.com/embed/" +
          video +
          "' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>";
      }
      if (value.indexOf("rutube.ru/video/") !== -1) {
        video = value.split("rutube.ru/video/")[1];
        video =
          "<iframe src='https://rutube.ru/play/embed/" +
          video +
          "' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>";
      }
      if (value.indexOf("vk.com/video") !== -1) {
        video = value.split("vk.com/video")[1].split("_");
        video =
          "<iframe src='https://vk.com/video_ext.php?oid=" +
          video[0] +
          "&id=" +
          video[1] +
          "&hd=2&autoplay=0' allow='encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;' frameborder='0' allowfullscreen></iframe>";
      }
      return video;
    },
    textCategories(categories) {
      let text = "";
      for (let item in categories) {
        if (text !== "") text += ", ";
        text += categories[item]["name"];
      }
      return text;
    },
    clickButton(item) {
      let url = "";
      if (item["button_page_id"]) {
        if (item["button_page"]["type"] === 3) url = "/catalog";
        if (item["button_page"]["type"] === 4) url = "/blog";
        if (item["button_page"]["type"] === 5) url = "/blog";
        if (item["button_page"]["type"] === 6) url = "/catalog";
        url += "/" + item["button_page"]["url"];
      } else url = item["button_external_reference"];
      location.href = url;
    },
  },
};
</script>
